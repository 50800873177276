import React, { useEffect } from 'react';
import './item-reacao.component.scss';

export type ItemReacaoComponentProps = {
  icone?: string;
  iconeClass?: any;
}

function ItemReacaoComponent(props: ItemReacaoComponentProps) {

  const [randomPos, setRandomPos] = React.useState<any>({});
  const [randomAnima, setRandomAnima] = React.useState<number>(0);
  const [itemActive, setItemActive] = React.useState<boolean>(true);

  var arrPos = ['right', 'left'];

  useEffect(() => {
    PosRandom();
    setTimeout(() => {
      setItemActive(false);
    }, 6000);
  }, []);

  const PosRandom = () => {

    var randomNumber = Math.floor(Math.random() * arrPos.length);
    setRandomAnima(randomNumber);

    if (arrPos[randomNumber] === 'left') {
      setRandomPos({ left: Math.random() * (100 - 0) + 0 });
    } else {
      setRandomPos({ right: Math.random() * (100 - 0) + 0 });
    }
  }

  return (
    <>
      {
        itemActive &&
        <div className={"item-reacao-component " + props.iconeClass + " anima-" + randomAnima} style={randomPos}>
          <div className="anima">
            <img src={props.icone} alt={props.iconeClass} />
          </div>
        </div>
      }
    </>
  );
}

export default ItemReacaoComponent;
