import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import ComentaiosComponent from '../../components/comentarios/comentarios.component';
import './transmissao-live.page.scss';
import UsuarioModel from '../login/usuario.model';
import { getAuthUser } from '../../services/auth';
import { PaginaTransmissaoLiveService } from '../../services/api-paginas.service';
import { SnackService } from '../../services/snack.service';
import TransmissaoLiveModel from './transmissao-live.model';
import Countdown from 'react-countdown';
import ReacoesComponent from '../../components/reacoes/reacoes.component';
import { environment } from '../../environments/environment';
import { Link } from 'react-router-dom';
import { Modal } from '@mui/material';
import { EnvieSuaPerguntaComponent } from '../../components/envie-sua-pergunta/envie-sua-pergunta.component';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function TransmissaoLivePage() {

  var dto: UsuarioModel = {};
  var usuario: UsuarioModel = getAuthUser();
  const [paginaTransmissaoLive, setPaginaTransmissaoLive] = React.useState<TransmissaoLiveModel>();
  const [eventoIniciou, setEventoIniciou] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  let data = new Date();
  let dataFormatada = ((data.getMonth() + 1)) + "/" + ((data.getDate())) + "/" + data.getFullYear() + " " + data.getHours() + ":" + data.getMinutes();

  var date_hj: any = new Date(dataFormatada);
  let dateFutLocalStorage = localStorage.getItem('data_e_hora_do_evento');
  var date_fut: any = new Date(dateFutLocalStorage ? dateFutLocalStorage : '');
  var elapsed: any = date_hj - date_fut;
  elapsed = elapsed.toString().replace('-', '');

  useEffect(() => {
    getPaginaTransmissaoLive();
    if (date_hj > date_fut) {
      setEventoIniciou(true);
    }
  }, []);

  const fimCountDown = () => {
    setEventoIniciou(true);
  }

  const getPaginaTransmissaoLive = () => {

    dto = {
      user_email: usuario.user_email
    };

    PaginaTransmissaoLiveService(dto).then((res) => {
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setPaginaTransmissaoLive(res.data);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const Completionist = () => <span>go!</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      if (hours.toString().length === 1) {
        hours = '0' + hours;
      }
      if (minutes.toString().length === 1) {
        minutes = '0' + minutes;
      }
      if (seconds.toString().length === 1) {
        seconds = '0' + seconds;
      }
      return <span>{days}D - {hours}:{minutes}:{seconds}</span>;
    }
  };

  const LinkPergunta = props => <Link to={environment.frontUrl + "/envie-sua-pergunta"} {...props} />


  return (
    <>
      <section className="page transmissao-live">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="box-video-e-count">
                {
                  paginaTransmissaoLive?.iframe_video &&
                  <div className="box-video" dangerouslySetInnerHTML={{ __html: paginaTransmissaoLive?.iframe_video }}>
                  </div>
                }
                <div className={"box-data-count flex flex-cv flex-ch flex-wr" + (!eventoIniciou && ' ativo')}>
                  {
                    paginaTransmissaoLive?.imagem_iframe &&
                    <div className="imagem-iframe" style={{ backgroundImage: 'url(' + paginaTransmissaoLive?.imagem_iframe + ')' }}></div>
                  }
                  <div className="box-count">
                    <h2>O evento começa em:</h2>
                    <Countdown renderer={renderer} onComplete={() => fimCountDown()} date={Date.now() + parseInt(elapsed)} />
                  </div>
                </div>
              </div>
              <ReacoesComponent />
              <div className="bts flex flex-ch">
                <Button onClick={() => setOpenModal(true)} className="bt">
                  Envie sua pergunta
                </Button>
                {
                  paginaTransmissaoLive?.url_agenda_reuniao &&
                  <Button className="bt" href={paginaTransmissaoLive?.url_agenda_reuniao} target="_blank">
                    {paginaTransmissaoLive?.legenda_botao}
                  </Button>
                }
              </div>
            </div>
            <div className="box-comentarios col-md-4">
              <ComentaiosComponent palavrasModeradasADM={paginaTransmissaoLive?.moderacao_chat} usuario={usuario.first_name} />
            </div>
          </div>
          {
            paginaTransmissaoLive?.logos &&
            <OwlCarousel className='owl-theme desktop' items={1} loop margin={10} autoplay dots={false} nav={false}>
              {
                paginaTransmissaoLive?.logos ? paginaTransmissaoLive.logos.map((item, indexItem) => (
                  <div className='item' key={indexItem}>
                    <img src={item} alt="Logo" />
                  </div>
                )) : <span>Nenhum logo encontrado.</span>
              }
            </OwlCarousel>
          }
          {
            paginaTransmissaoLive?.arte_full_desktop &&
            <div className="area-arte-full flex flex-ch">
              <img className="desktop" src={paginaTransmissaoLive?.arte_full_desktop} alt="Arte full" />
              <img className="mobile" src={paginaTransmissaoLive?.arte_full_mobile} alt="Arte full" />
            </div>
          }
        </div>
      </section>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EnvieSuaPerguntaComponent fecharModal={() => { setOpenModal(false) }} />
      </Modal>
    </>
  );
}

export default TransmissaoLivePage;
