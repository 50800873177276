import axios from "axios";
import ComentariosModel from "../components/comentarios/comentarios.model";
import { environment } from "../environments/environment";
import CategoriasModel from "../pages/downloads/categorias.model";
import UsuarioModel from "../pages/login/usuario.model";

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function ListarCategoriasService(dto: UsuarioModel) {
    let url = '/apis/listar-categorias-downloads';
    return await api.post(url, dto, {});
}

export async function ListarDownloadsService(dto: CategoriasModel) {
    let url = '/apis/listar-downloads-por-categoria';
    return await api.post(url, dto, {});
}