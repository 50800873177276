import axios from "axios";
import ComentariosModel from "../components/comentarios/comentarios.model";
import { environment } from "../environments/environment";

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function ListarComentariosService(dto: ComentariosModel) {
    let url = '/apis/listar-comentarios';
    return await api.post(url, dto, { cancelToken: dto.cancel });
}

export async function InserirComentarioService(dto: ComentariosModel) {
    let url = '/apis/inserir-comentarios';
    return await api.post(url, dto, {});
}