import { Button, Rating, TextField } from '@mui/material';
import React from 'react';
import { useInput } from '../../hooks/useInput.hook';
import { InserirPesquisaService } from '../../services/api-paginas.service';
import { getAuthUser } from '../../services/auth';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import UsuarioModel from '../login/usuario.model';
import PesquisaSatisfacaoModel from './pesquisa-satisfacao.model';
import './pesquisa-satisfacao.page.scss';

export function PesquisaSatisfacaoPage() {

  const [comunicacao, setComunicacao] = React.useState<number | null>(0);
  const [qualidadePlataforma, setQualidadePlataforma] = React.useState<number | null>(0);
  const [qualidadeApresentadores, setQualidadeApresentadores] = React.useState<number | null>(0);
  const [qualidadeProgramacao, setQualidadeProgramacao] = React.useState<number | null>(0);
  const [qualidadeProdutos, setQualidadeProdutos] = React.useState<number | null>(0);
  const [potencial, setPotencial] = React.useState<number | null>(0);
  const { value: comentariosInputText, bind: bindComentariosInputText, setValue: setComentariosInputText } = useInput('');

  var dto: PesquisaSatisfacaoModel;
  var usuario: UsuarioModel = getAuthUser();

  const InserirPesquisa = () => {

    if (comunicacao === 0) {
      SnackService.sendMSG('Quantas estrelas para "Comunicação / divulgação do evento"?');
      return;
    } else if (qualidadePlataforma === 0) {
      SnackService.sendMSG('Quantas estrelas para "Qualidade da plataforma"?');
      return;
    } else if (qualidadeApresentadores === 0) {
      SnackService.sendMSG('Quantas estrelas para "Qualidade dos apresentadores"?');
      return;
    } else if (qualidadeProgramacao === 0) {
      SnackService.sendMSG('Quantas estrelas para "Qualidade da programação / agenda do evento"?');
      return;
    } else if (qualidadeProdutos === 0) {
      SnackService.sendMSG('Quantas estrelas para "Qualidade dos produtos apresentados"?');
      return;
    } else if (potencial === 0) {
      SnackService.sendMSG('Quantas estrelas para "Potencial de vendas da coleção Gear & Garment 2022"?');
      return;
    }

    dto = {
      user_id: usuario.ID,
      first_name: usuario.first_name,
      comunicacao: comunicacao,
      qualidade_plataforma: qualidadePlataforma,
      qualidade_apresentadores: qualidadeApresentadores,
      qualidade_programacao: qualidadeProgramacao,
      qualidade_produtos: qualidadeProdutos,
      potencial_de_vendas: potencial,
      comentarios_duvidas_sugestoes: comentariosInputText
    };

    LoadingService.sendLoadingAtivo(true);

    InserirPesquisaService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        SnackService.sendMSG('Pesquisa enviada, muito obrigado!');
        LimparPesquisa();
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const LimparPesquisa = () => {
    setComunicacao(0);
    setQualidadePlataforma(0);
    setQualidadeApresentadores(0);
    setQualidadeProgramacao(0);
    setQualidadeProdutos(0);
    setPotencial(0);
    setComentariosInputText('');
  }

  return (
    <section className="page pesquisa-satisfacao">
      <div className="container">
        <h1>Sua opinião sobre nosso evento</h1>
        <h2>É muito importante</h2>
        <h3>COMO AVALIA O EVENTO REALIZADO?</h3>
        <div className="form">
          <div className="campo flex flex-sp flex-cv">
            <h4>Comunicação / divulgação do evento:</h4>
            <Rating
              name="comunicacao"
              value={comunicacao}
              onChange={(event, newValue) => {
                setComunicacao(newValue);
              }} />
          </div>
          <div className="campo flex flex-sp flex-cv">
            <h4>Qualidade da plataforma:</h4>
            <Rating
              name="qualidadePlataforma"
              value={qualidadePlataforma}
              onChange={(event, newValue) => {
                setQualidadePlataforma(newValue);
              }} />
          </div>
          <div className="campo flex flex-sp flex-cv">
            <h4>Qualidade dos apresentadores:</h4>
            <Rating
              name="qualidadeApresentadores"
              value={qualidadeApresentadores}
              onChange={(event, newValue) => {
                setQualidadeApresentadores(newValue);
              }} />
          </div>
          <div className="campo flex flex-sp flex-cv">
            <h4>Qualidade da programação / agenda do evento:</h4>
            <Rating
              name="qualidadeProgramacao"
              value={qualidadeProgramacao}
              onChange={(event, newValue) => {
                setQualidadeProgramacao(newValue);
              }} />
          </div>
          <div className="campo flex flex-sp flex-cv">
            <h4>Atratividade dos produtos apresentados:</h4>
            <Rating
              name="qualidadeProdutos"
              value={qualidadeProdutos}
              onChange={(event, newValue) => {
                setQualidadeProdutos(newValue);
              }} />
          </div>
          <div className="campo flex flex-sp flex-cv">
            <h4>Potencial de vendas da coleção Gear & Garment 2022:</h4>
            <Rating
              name="potencial"
              value={potencial}
              onChange={(event, newValue) => {
                setPotencial(newValue);
              }} />
          </div>

          <TextField multiline {...bindComentariosInputText} label="Comentários, dúvidas ou sugestões" />
          <Button className="bt" onClick={InserirPesquisa}>
            Enviar
          </Button>
        </div>
      </div>
    </section>
  );
}
