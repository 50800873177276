import { Button } from '@mui/material';
import React from 'react';
import './footer.component.scss';
import logoFoot from '../../assets/logo-mercermarsh.svg';

function FooterComponent() {

  return (
    <footer>
      <div className="container-fluid">
        <img src={logoFoot} alt="Logo" />
        <div className="flex flex-sp flex-cv flex-wr">
          <h4>
            MMB Brasil 2022 @Todos os direitos reservados.
          </h4>
          <h4>
            Orgulhosamente desenvolvido por <Button href="https://www.sectorone.com.br/" target="_blank">Sector One</Button>.
          </h4>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
