import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import './reacoes.component.scss';

import burn from '../../assets/emoticons/burn_fire_flame_hot_icon.svg';
import care from '../../assets/emoticons/care_social_emoticon_emoji_smiley_icon.svg';
import love from '../../assets/emoticons/love_heart_favorite_social_expression_icon.svg';
import smile from '../../assets/emoticons/smile_emoticon_emoji_face_smiley_icon.svg';
import sad from '../../assets/emoticons/upset_sad_face_emoji_expression_icon.svg';
import wow from '../../assets/emoticons/wow_emoji_face_emoticon_emotion_icon.svg';
import ItemReacaoComponent from '../item-reacao/item-reacao.component';
import ReacoesModel from './reacoes.model';
import UsuarioModel from '../../pages/login/usuario.model';
import { getAuthUser } from '../../services/auth';
import { InserirReacaoService, ListarReacoesService } from '../../services/api-reacoes.service';
import { SnackService } from '../../services/snack.service';
import axios from 'axios';
import { SocketService } from '../../services/socket.service';
import { LoadingService } from '../../services/loading.service';

function ReacoesComponent() {

  const [itensReacoes, setItensReacoes] = React.useState<Array<string | undefined>>([]);
  const [itensReacoesIcones, setItensReacoesIcones] = React.useState<Array<any>>([]);
  const [podeAddReacao, setPodeAddReacao] = React.useState<boolean>(true);

  const sourceApiReacoes = axios.CancelToken.source();

  var dto: ReacoesModel = {};
  var usuario: UsuarioModel = getAuthUser();

  useEffect(() => {
    //ListarReacoes(1);

    SocketService.on('resNodeReacoesMercer', (itensRes: any) => {
      console.log('itensRes >>' + JSON.stringify(itensRes));
      LoadingService.sendLoadingAtivo(false);
      addReacao(itensRes);
      // let itens: ReacoesModel[] = itensRes;
      // if (itensRes.data.first == false) {
      //   addReacao(itens);
      // }
    });

    return function cleanup() {
      sourceApiReacoes.cancel("CancelReacao");
    };
  }, []);

  useEffect(() => {
    //console.log(itensReacoes + ' <- itensReacoes');

  }, [itensReacoes]);

  const addReacao = (reacoes: Array<ReacoesModel>) => {

    setPodeAddReacao(true);

    reacoes.map(item => {
      setTimeout(() => {
        setItensReacoes(prev => ([...prev, item.reacao]));

        if (item.reacao === 'burn') {
          setItensReacoesIcones(prev => ([...prev, burn]));
        } else if (item.reacao === 'care') {
          setItensReacoesIcones(prev => ([...prev, care]));
        } else if (item.reacao === 'love') {
          setItensReacoesIcones(prev => ([...prev, love]));
        } else if (item.reacao === 'smile') {
          setItensReacoesIcones(prev => ([...prev, smile]));
        } else if (item.reacao === 'sad') {
          setItensReacoesIcones(prev => ([...prev, sad]));
        } else if (item.reacao === 'wow') {
          setItensReacoesIcones(prev => ([...prev, wow]));
        }
      }, 500);
    })
  }

  const InserirReacao = (reacao: string) => {
    setPodeAddReacao(false);
    LoadingService.sendLoadingAtivo(true);

    dto = {
      id: usuario.ID,
      reacao: reacao,
      usuario: usuario.first_name
    };

    // envia socket - enviaComentarioSocket
    SocketService.emit('enviaReacaoSocket', dto);
  }

  // const InserirReacao = (reacao: string) => {
  //   setPodeAddReacao(false);
  //   dto = {
  //     id: usuario.ID,
  //     reacao: reacao,
  //     usuario: usuario.first_name
  //   };
  //   InserirReacaoService(dto).then((res) => {
  //     setPodeAddReacao(true);
  //     if (res.data.error) {
  //       SnackService.sendMSG(res.data.error);
  //     } else {
  //       //MSGInputText('');
  //     }
  //   }).catch(err => {
  //     setPodeAddReacao(true);
  //     SnackService.sendMSG('Erro com a conexão.');
  //     console.log(err);
  //   });
  // }

  const ListarReacoes = (id_post: number | undefined) => {

    dto.id = id_post;
    dto.cancel = sourceApiReacoes.token;

    ListarReacoesService(dto).then((res) => {
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        let itens: ReacoesModel[] = res.data.reacoes;
        if (res.data.first == false) {
          addReacao(itens);
        }
        ListarReacoes(itens[0].id);
      }
    }).catch(err => {
      if (err.message === 'CancelReacao') {

      } else {
        SnackService.sendMSG('Erro com a conexão ao listar reações.');
        console.log(err);
      }
    });
  }

  return (
    <div className="reacoes-component">
      <div className="anima-reacoes">
        {
          itensReacoes?.map((item, index) => (
            <ItemReacaoComponent key={index} iconeClass={item} icone={itensReacoesIcones[index]} />
          ))
        }
      </div>
      <div className={"reacoes flex flex-cv flex-ch " + (podeAddReacao && 'ativo')}>
        <Button onClick={() => InserirReacao('care')}>
          <img src={care} alt="Care" />
        </Button>
        <Button onClick={() => InserirReacao('love')}>
          <img src={love} alt="Love" />
        </Button>
        <Button onClick={() => InserirReacao('smile')}>
          <img src={smile} alt="Smile" />
        </Button>
        <Button onClick={() => InserirReacao('sad')}>
          <img src={sad} alt="Sad" />
        </Button>
        <Button onClick={() => InserirReacao('wow')}>
          <img src={wow} alt="Wow" />
        </Button>
        <Button onClick={() => InserirReacao('burn')}>
          <img src={burn} alt="Burn" />
        </Button>
      </div>
    </div>
  );
}

export default ReacoesComponent;
