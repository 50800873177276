import * as React from 'react';
import { NavLink, useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import logo from '../../assets/logo-mercermarsh.svg';
import './header.component.scss';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Kv3Forum from '../../assets/lp/kv.svg';
import { environment } from '../../environments/environment';
import { Logout } from '@mui/icons-material';
import { Tooltip } from '@mui/material';


function HeaderComponent() {

  let history = useHistory();

  const sairService = () => {
    localStorage.removeItem('user');
    history?.push({
      pathname: environment.frontUrl + '/login'
    })
  }

  return (
    <>
      <header>

        <div className="container-fluid">
          <div className="flex flex-sp">
            <img className="kv" src={Kv3Forum} alt="KV rosa" />

            <div className="dir">
              <Button>
                <NavLink exact={true} to={environment.frontUrl + "/area-usuario"}>
                  Menu
                </NavLink>
              </Button>
            </div>
          </div>
        </div>
      </header>
      <Button className="bt-sair" onClick={sairService}>
        <Tooltip title="Sair">
          <Logout />
        </Tooltip>
      </Button>
    </>
  );
}

export default HeaderComponent;
