import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import './area-usuario.page.scss';
import { NavLink } from 'react-router-dom';
import UsuarioModel from '../login/usuario.model';
import { getAuthUser } from '../../services/auth';
import { PaginaAreaUsuarioService } from '../../services/api-paginas.service';
import { SnackService } from '../../services/snack.service';
import AreaUsuarioModel from './area-usuario.model';
import { LoadingService } from '../../services/loading.service';
import { environment } from '../../environments/environment';

function AreaUsuarioPage() {

  var dto: UsuarioModel = {};
  var usuario: UsuarioModel = getAuthUser();
  const [menusAreaUsuario, setMenusPaginaAreaUsuario] = React.useState<Array<AreaUsuarioModel>>([]);

  useEffect(() => {
    getPaginaAreaUsuario();

    document.body.classList.add("area-usuario-body");

    return function cleanup() {
      document.body.classList.remove("area-usuario-body");
    };
  }, []);

  const getPaginaAreaUsuario = () => {

    dto = {
      user_email: usuario.user_email
    };

    LoadingService.sendLoadingAtivo(true);

    PaginaAreaUsuarioService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setMenusPaginaAreaUsuario(res.data.menus);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  return (
    <section className="page area-usuario flex flex-cv">
      <div className="container-fluid">
        <div className={"itens flex flex-ch flex-cv flex-wr tipo-perfil-" + usuario.tipo_perfil}>
          {
            menusAreaUsuario ? menusAreaUsuario?.map((item, indexItem) => (
              <NavLink className={item.ativo === 'Não' ? 'inativo' : ''} key={indexItem} exact={true} to={environment.frontUrl + item.link_pagina}>
                <Button>
                  <div className="txt">
                    <img src={item.icone} alt={item.legenda} />
                    <h3 dangerouslySetInnerHTML={{ __html: item.legenda }}></h3>
                    <span>
                      {
                        item.ativo === 'Não' ?
                        'Em breve!' :
                        'Visualizar'
                      }
                    </span>
                  </div>
                </Button>
              </NavLink>
            )) : <span>Nenhum item encontrado.</span>
          }
        </div>
      </div>
    </section>
  );
}

export default AreaUsuarioPage;
