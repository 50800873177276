import { Instagram } from '@material-ui/icons';
import { ArrowDropDown, Email, Facebook, LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect } from 'react';
import { PaginaPalestrantesService } from '../../services/api-paginas.service';
import { getAuthUser } from '../../services/auth';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import UsuarioModel from '../login/usuario.model';
import PalestrantesModel from './palestrantes.model';
import './palestrantes.page.scss';

export function PalestrantesPage() {

  var usuario: UsuarioModel = getAuthUser();
  var dto: UsuarioModel = {};

  const [listaPalestrantes, setListaPalestrantes] = React.useState<Array<PalestrantesModel>>([]);

  useEffect(() => {
    PaginaPalestrantes();
  }, []);

  const PaginaPalestrantes = () => {

    dto = {
      user_email: usuario.user_email
    };

    LoadingService.sendLoadingAtivo(true);

    PaginaPalestrantesService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListaPalestrantes(res.data.palestrantes);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  return (
    <section className="page palestrantes">
      <div className="container">
        <h1>Palestrantes</h1>

        <div className="itens flex flex-wr flex-ch">
          {
            listaPalestrantes ? listaPalestrantes?.map((item, indexItem) => (
              <div key={indexItem} className="item flex">
                <div className="txt">
                  <div className="box-img">
                    <img src={item.foto} alt={item.nome} />
                  </div>
                  <h3>
                    <span dangerouslySetInnerHTML={{ __html: item.nome }}></span>
                  </h3>
                  <div className="foot flex flex-sp">
                    <div className="palestras">
                      <PopupState variant="popover" popupId={"demo-popup-menu-" + indexItem}>
                        {(popupState) => (
                          <React.Fragment>
                            <Button variant="contained" {...bindTrigger(popupState)}>
                              SOBRE <ArrowDropDown />
                            </Button>
                            <Menu className="menu-palestras" {...bindMenu(popupState)}>
                              {
                                item.palestras ? item.palestras.map((itemPalestra, indexPalestra) => (
                                  <div key={indexPalestra} dangerouslySetInnerHTML={{ __html: itemPalestra }}>
                                  </div>
                                )) : <span>Nenhum item encontrado.</span>
                              }
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </div>
                    <div className="redes-sociais flex flex-cv">
                      {
                        item.redes_sociais ? item.redes_sociais.map((itemRedeSocial, indexRedeSocial) => (
                          <Button key={indexRedeSocial} href={itemRedeSocial.url} target="_blank">
                            {
                              itemRedeSocial.rede_social === 'Facebook' &&
                              <Facebook />
                            }
                            {
                              itemRedeSocial.rede_social === 'Instagram' &&
                              <Instagram />
                            }
                            {
                              itemRedeSocial.rede_social === 'Youtube' &&
                              <YouTube />
                            }
                            {
                              itemRedeSocial.rede_social === 'Twitter' &&
                              <Twitter />
                            }
                            {
                              itemRedeSocial.rede_social === 'E-mail' &&
                              <Email />
                            }
                            {
                              itemRedeSocial.rede_social === 'Linkedin' &&
                              <LinkedIn />
                            }
                          </Button>
                        )) : <span>Nenhum item encontrado.</span>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )) : <span>Nenhum item encontrado.</span>
          }
        </div>
      </div>
    </section>
  );
}
