import { Close } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import React from 'react';
import { useInput } from '../../hooks/useInput.hook';
import UsuarioModel from '../../pages/login/usuario.model';
import { InserirPerguntaService } from '../../services/api-paginas.service';
import { getAuthUser } from '../../services/auth';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import './envie-sua-pergunta.component.scss';
import PerguntaModel from './pergunta.model';

export type EnvieSuaPerguntaComponenttProps = {
  fecharModal: () => void;
}

export function EnvieSuaPerguntaComponent(props: EnvieSuaPerguntaComponenttProps) {

  var dto: PerguntaModel = {};
  var usuario: UsuarioModel = getAuthUser();

  const { value: perguntaInputText, bind: bindPerguntaInputText, setValue: setPerguntaInputText } = useInput('');

  const InserirPergunta = () => {
    if (perguntaInputText === '') {
      SnackService.sendMSG('Preencha o campo pergunta.');
      return;
    }

    LoadingService.sendLoadingAtivo(true);

    dto = {
      id: usuario.ID,
      first_name: usuario.first_name,
      email: usuario.user_email,
      pergunta: perguntaInputText
    };
    InserirPerguntaService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        if (res.data.sucesso) {
          setPerguntaInputText('');
          SnackService.sendMSG(res.data.sucesso);
        } else {
          SnackService.sendMSG('Erro ao enviar pergunta.');
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão ao enviar pergunta.');
      console.log(err);
    });
  }

  return (
    <div className="envie-sua-pergunta-component">
      <h1>Envie sua pergunta</h1>
      <TextField multiline {...bindPerguntaInputText} label="Pergunta" />
      <Button className="bt" onClick={InserirPergunta}>
        Enviar
      </Button>
      <Button className="fechar" onClick={props.fecharModal}>
        <Close />
      </Button>
    </div>
  );
}
