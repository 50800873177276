import { AccountCircle, Add, CloudUpload } from '@material-ui/icons';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, TextField, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { useInput } from '../../hooks/useInput.hook';
import { UploadImagemMinhaContaService } from '../../services/api-minha-conta.service';
import { AtualizarUsuarioService } from '../../services/api-usuario.service';
import { getAuthUser } from '../../services/auth';
import { CartaoVirtualService } from '../../services/cartao-virtual.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import UsuarioModel from '../login/usuario.model';
import './minha-conta.page.scss';

export function MinhaContaPage() {

  const [usuario, setUsuario] = React.useState<UsuarioModel>(getAuthUser());

  const [fileSelected, setFileSelected] = React.useState<File | undefined>();
  const [foto, setFoto] = React.useState<string | undefined>(usuario.foto);
  const [refFile, setRefFile] = React.useState<any>();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const { value: nomeInputText, bind: bindNomeInputText, setValue: setNomeInputText } = useInput(usuario.first_name);
  const { value: cpfInputText, bind: bindCPFInputText, setValue: setCPFInputText } = useInput(usuario.cpf);
  const { value: emailInputText, bind: bindEmailInputText, setValue: setEmailInputText } = useInput(usuario.user_email);
  const { value: senhaInputText, bind: bindSenhaInputText, setValue: setSenhaInputText } = useInput('');

  const { value: telefoneInputText, bind: bindTelefoneInputText, setValue: setTelefoneInputText } = useInput(usuario.telefone);
  const { value: linkedinInputText, bind: bindLinkedinInputText, setValue: setLinkedinInputText } = useInput(usuario.linkedin);
  const { value: instagramInputText, bind: bindInstagramInputText, setValue: setInstagramInputText } = useInput(usuario.instagram);

  useEffect(() => {
    document.body.classList.add("minha-conta-body");

    return function cleanup() {
      document.body.classList.remove("minha-conta-body");
    };
  }, []);

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;

    setFileSelected(fileList[0]);
    //uploadFile();
  };

  const uploadFile = function () {
    if (fileSelected) {
      const formData = new FormData();
      formData.append("file", fileSelected, fileSelected.name + '-777-' + usuario.ID);
      formData.append('action', 'md_support_save');

      UploadImagemMinhaConta(formData);
    }
  };

  const UploadImagemMinhaConta = (formData: any) => {

    LoadingService.sendLoadingAtivo(true);

    UploadImagemMinhaContaService(formData, usuario.ID).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        if (res.data.error) {
          SnackService.sendMSG(res.data.error);
        } else {
          setFoto(res.data.foto);
          setFileSelected(undefined);
          SnackService.sendMSG('Imagem alterada!');

          let tempUser = usuario;
          tempUser.foto = res.data.foto;
          setUsuario(tempUser);
          localStorage.setItem("user", JSON.stringify(tempUser));
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const AtualizarUsuario = () => {

    if (nomeInputText === '') {
      SnackService.sendMSG('Informe o nome completo!');
      return;
    } else if (emailInputText === '') {
      SnackService.sendMSG('Informe o e-mail!');
      return;
    } else if (senhaInputText === '') {
      SnackService.sendMSG('Informe a senha!');
      return;
    }

    LoadingService.sendLoadingAtivo(true);

    let tempUser = usuario;

    tempUser.first_name = nomeInputText;
    tempUser.cpf = cpfInputText;
    tempUser.user_email = emailInputText;
    tempUser.user_pass = senhaInputText;
    tempUser.telefone = telefoneInputText;
    tempUser.linkedin = linkedinInputText;
    tempUser.instagram = instagramInputText;

    AtualizarUsuarioService(tempUser).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        if (res.data.error) {
          SnackService.sendMSG(res.data.error);
        } else {
          tempUser.user_pass = '';
          setUsuario(tempUser);
          localStorage.setItem("user", JSON.stringify(tempUser));
          SnackService.sendMSG('Usuário atualizado!');
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const VerCartao = () => {
    CartaoVirtualService.sendCartaoVirtual(usuario);
  }

  return (
    <section className="page minha-conta">
      <div className="container-fluid">
        <h1>MEUS DADOS PESSOAIS</h1>
        <div className="flex flex-cv flex-ch flex-wr">
          <div className="esq">
            <div className="dados-pessoais">
              <TextField {...bindNomeInputText} label="Nome completo" />
              {/* <TextField {...bindCPFInputText} label="CPF" /> */}
              <TextField {...bindEmailInputText} label="E-mail" />
              <div className="box-pass">
                <TextField type={showPassword ? "text" : "password"} {...bindSenhaInputText} label="Troque sua senha" />
                <Button className="bt-toggle-pass" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </Button>
              </div>
            </div>
            <div className="dado-contato">
              <h2>
                CARTÃO VIRTUAL: MEUS DADOS PÚBLICOS
              </h2>
              <p>
                Preencha os campos com os seus dados que serão visíveis para os outros participantes do evento. Caso não queira divulgar seus dados, deixe os campos em branco.
              </p>
              <TextField {...bindTelefoneInputText} label="Telefone/Whatsapp" />
              <TextField {...bindLinkedinInputText} label="Linkedin" />
              <TextField {...bindInstagramInputText} label="Instagram" />
            </div>
          </div>
          <div className="dir flex flex-ch">
            <div className="box">
              <div className="ico-usu">
                <div className="box-foto flex flex-ch">
                  {
                    foto ?
                      <i style={{ backgroundImage: 'url(' + foto + ')' }}></i>
                      :
                      <AccountCircle />
                  }
                </div>
                <div className="box-bt">
                  {
                    fileSelected ?
                      <Button className="bt-upload" onClick={uploadFile}>
                        <Tooltip title="Fazer upload e alterar">
                          <CloudUpload />
                        </Tooltip>
                      </Button>
                      :
                      <Button className="bt-add" onClick={() => refFile.click()}>
                        <Tooltip title="Escolher imagem">
                          <Add />
                        </Tooltip>
                      </Button>
                  }
                </div>

                <h4>Foto perfil</h4>
                <input
                  accept="image/*"
                  id="photo"
                  name="photo"
                  type="file"
                  style={{ display: 'none' }}
                  multiple={false}
                  onChange={handleImageChange}
                  ref={input => setRefFile(input)}
                />

              </div>
              <Button className="bt branco" onClick={VerCartao}>
                Ver meu cartão virtual
              </Button>
            </div>
          </div>
        </div>
        <div className="box-bt-salvar">
          <Button onClick={AtualizarUsuario} className="bt branco">Salvar</Button>
        </div>
      </div>
    </section>
  );
}
