import { CircularProgress } from '@material-ui/core';
import { Button, TextField, Tooltip } from '@material-ui/core';

import axios from 'axios';
import React, { useEffect } from 'react';
import { environment } from '../../environments/environment';
import { useInput } from '../../hooks/useInput.hook';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import './comentarios.component.scss';
import ComentariosModel from './comentarios.model';
import { SocketService } from '../../services/socket.service';
import { ListarComentariosService } from '../../services/api-comentarios.service';
import { GetUserCartaoVirtualService } from '../../services/api-paginas.service';
import { CartaoVirtualService } from '../../services/cartao-virtual.service';
import UsuarioModel from '../../pages/login/usuario.model';
import { getAuthUser } from '../../services/auth';

export type ComentaiosComponentProps = {
  palavrasModeradasADM?: Array<string>;
  usuario?: string;
}

function ComentaiosComponent(props: ComentaiosComponentProps) {

  const [listComentarios, setListComentarios] = React.useState<Array<ComentariosModel>>([]);
  const [isFormActive, setIsFormActive] = React.useState<boolean>(false);
  const [isLoadActive, setIsLoadActive] = React.useState<boolean>(false);
  const { value: msgInputText, bind: bindMSGInputText, setValue: MSGInputText } = useInput('');

  var dto: ComentariosModel = {};
  var user_dto: UsuarioModel = {};
  var usuario: UsuarioModel = getAuthUser();

  const sourceApiComentarios = axios.CancelToken.source();

  useEffect(() => {
    ListarComentarios(1, 50, 0);

    SocketService.on('resNodeChatMercer', (itens: any) => {
      setIsLoadActive(false);
      MSGInputText('');
      setListComentarios(itens);
    });

    return function cleanup() {
      sourceApiComentarios.cancel("CancelComentario");
    };
  }, []);

  useEffect(() => {
  }, [msgInputText]);


  const ListarComentarios = (id_post: number | undefined, qtd_posts: number, count_posts: number) => {

    dto.id = id_post;
    dto.qtd = qtd_posts;
    dto.count_posts = count_posts;
    dto.cancel = sourceApiComentarios.token;

    ListarComentariosService(dto).then((res) => {
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        let itens: ComentariosModel[] = res.data.comentarios;
        setListComentarios(itens);
        //ListarComentarios(itens[itens.length - 1].id, qtd_posts, parseInt(res.data.count_posts));
      }
    }).catch(err => {
      if (err.message === 'CancelComentario') {

      } else {
        SnackService.sendMSG('Erro com a conexão ao listar comentários.');
        console.log(err);
      }
    });
  }

  const InserirComentario = () => {
    let msgUSU_moderada = msgInputText;
    let palavrasModeradas = props.palavrasModeradasADM;
    setIsLoadActive(true);

    palavrasModeradas?.map(item_palavra_moderada => {
      msgUSU_moderada = (msgUSU_moderada).replaceAll(item_palavra_moderada, '&%$#@');
    });

    msgUSU_moderada = msgUSU_moderada.replace(/(?:\r\n|\r|\n)/g, '<br>');

    dto = {
      id: 1,
      comentario: msgUSU_moderada,
      usuario: props.usuario,
      foto: usuario.foto
    };

    // envia socket - callReactChat
    SocketService.emit('enviaComentarioSocket', dto);
  }

  const handleKeywordKeyPress = (e: any) => {
    if (msgInputText) {
      setIsFormActive(true);
      if (e.key == 'Enter') {
        //InserirComentario();
      }
    } else {
      setIsFormActive(false);
    }
  };

  const CartaoVirtual = (user_id?: number) => {

    user_dto = {
      ID: user_id
    }
    LoadingService.sendLoadingAtivo(true);
    GetUserCartaoVirtualService(user_dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        CartaoVirtualService.sendCartaoVirtual(res.data.dados_us);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  return (
    <>
      <div className="comentarios">
        <h2>Chat ao vivo</h2>
        <div className="box">
          {
            listComentarios ? listComentarios?.map((item, indexItem) => (
              <div className="item" key={indexItem}>
                <Tooltip title="Ver cartão virtual">
                  <h3 onClick={() => CartaoVirtual(item.id_usuario)} className="flex flex-cv">
                    {
                      item?.foto &&
                      <i style={{ backgroundImage: 'url(' + item.foto + ')' }}></i>
                    }
                    {item.usuario}
                  </h3>
                </Tooltip>
                <div className="txt">
                  {item.comentario}
                </div>
              </div>
            )) : <span>Nenhum item encontrado.</span>
          }
        </div>
        <div className={"form flex flex-sp flex-cv " + (isFormActive && ' ativo')}>
          <TextField onKeyUp={handleKeywordKeyPress} {...bindMSGInputText} label="Envie sua mensagem" />
          <Button className="bt" onClick={() => InserirComentario()}>
            Enviar
          </Button>
        </div>
        <div className={'loading-comments flex flex-ch flex-cv ' + (isLoadActive && 'ativo')}>
          <CircularProgress />
        </div>
      </div>
    </>
  );
}

export default ComentaiosComponent;
