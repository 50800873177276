import axios from "axios";
import { environment } from "../environments/environment";
import UsuarioModel from "../pages/login/usuario.model";

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function LoginService(dto: UsuarioModel) {
    let url = '/apis/login-usuario';
    return await api.post(url, dto, {});
}

export async function CadastrarService(dto: UsuarioModel) {
    let url = '/apis/cadastrar-usuario';
    return await api.post(url, dto, {});
}

export async function AtualizarUsuarioService(dto: UsuarioModel) {
    let url = '/apis/atualizar-usuario';
    return await api.post(url, dto, {});
}

export async function EsqueciMinhaSenhaService(dto: UsuarioModel) {
    let url = '/apis/esqueci-minha-senha';
    return await api.post(url, dto, {});
}