import React, { useEffect } from 'react';
import './login.page.scss';
import LoginComponent from './login/login.component';
import logo from '../../assets/logo-mercermarsh.svg';
import logoFoot from '../../assets/logo-mercermarsh.svg';
import logosFoot from '../../assets/lp/logos-foot.png';

import Kv3Forum from '../../assets/lp/kv-3-forum.png';
import KvAzul from '../../assets/lp/kv-azul-claro.png';

import fotosLocal from '../../assets/lp/fotos-local.png';
import CadastroComponent from './cadastro/cadastro.component';
import Countdown from 'react-countdown';
import { SnackService } from '../../services/snack.service';
import { isAuhenticated } from '../../services/auth';
import { useHistory } from "react-router-dom";
import { Button } from '@mui/material';
import { PaginaLoginService } from '../../services/api-paginas.service';
import UsuarioModel from './usuario.model';
import { LoadingService } from '../../services/loading.service';
import { environment } from '../../environments/environment';
import { EsqueciMinhaSenhaComponent } from '../../components/esqueci-minha-senha/esqueci-minha-senha.component';
import CadastroPresencialComponent from './cadastro-presencial/cadastro-presencial.component';
import { Close } from '@material-ui/icons';

function LoginPage() {

  let history = useHistory();

  const [showBox, setShowBox] = React.useState<string>('login');
  const [showModalLogin, setShowModalLogin] = React.useState<boolean>(false);
  const [diaMes, setDiaMes] = React.useState<string>('');

  let data = new Date();
  let dataFormatada = ((data.getMonth() + 1)) + "/" + ((data.getDate())) + "/" + data.getFullYear() + " " + data.getHours() + ":" + data.getMinutes();
  var date_hj: any = new Date(dataFormatada);
  const [statusCountDown, setStatusCountDown] = React.useState<string>('aguardando');
  const [elapsedStage, setElapsedStage] = React.useState<string>('');

  var dto: UsuarioModel = {};

  useEffect(() => {
    LoadingService.sendLoadingAtivo(true);
    getPaginaLogin();

    document.body.className = '';
    if (isAuhenticated()) {
      history?.push({
        pathname: environment.frontUrl + '/area-usuario'
      })
    } else {
      document.body.classList.add("login");
    }

  }, []);

  const startCountDown = (date_fut_DB: string) => {
    var date_fut: any = new Date(date_fut_DB);
    var elapsed: any = date_hj - date_fut;
    elapsed = elapsed.toString().replace('-', '');
    setElapsedStage(elapsed);
    if (date_hj > date_fut) {
      setStatusCountDown('encerrado');
    } else {
      setStatusCountDown('countdown');
    }
  }

  const fimCountDown = () => {
    SnackService.sendMSG('Correeee!! Acabou de começar o evento =)');
  }

  const getPaginaLogin = () => {

    dto = {
      user_email: 'email'
    };

    PaginaLoginService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        let dataHomeDB = res.data.login.data_e_hora_do_evento.split(' ');
        let diaMesDB = dataHomeDB[0].split('/');
        let diaMesFormatado = diaMesDB[1] + '.' + diaMesDB[0] + '.' + diaMesDB[2].substring(2);
        setDiaMes(diaMesFormatado);
        startCountDown(res.data.login.data_e_hora_do_evento);
        localStorage.setItem('data_e_hora_do_evento', res.data.login.data_e_hora_do_evento);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err + ' < err');
    });
  }

  const Completionist = () => <span>go!</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      if (hours.toString().length === 1) {
        hours = '0' + hours;
      }
      if (minutes.toString().length === 1) {
        minutes = '0' + minutes;
      }
      if (seconds.toString().length === 1) {
        seconds = '0' + seconds;
      }
      return <span>{days}D - {hours}:{minutes}:{seconds}</span>;
    }
  };

  return (
    <>
      <section className="gestao-saude">
        <div className="container">
          <div className="flex flex-sp">
            <img className="logo" src={logo} alt="Logo" />
            <Button onClick={() => { setShowModalLogin(true) }}>login</Button>
          </div>
        </div>
        <img className="kv" src={Kv3Forum} alt="KV rosa" />
      </section>
      <section className="evento-comeca">
        <div className="container">
          <div className="flex flex-wr">
            <div className="esq">
              <h3>
                Dia 20 de Setembro, às 9h.
              </h3>
            </div>
          </div>
        </div>
        <div className="full-area linha-azul">

          <div className="container">
            <i className="linha-azul-v2"></i>
            <h4>Evento híbrido</h4>
            <p>
              Presencial em São Paulo com transmissão ao vivo para todo o Brasil.
            </p>
          </div>
        </div>
      </section>
      <section className="sobre-o-evento">
        <div className="container">
          <h2>
            <i></i>
            <span>
              Sobre o Evento
            </span>
          </h2>
          <p>
            A pandemia de Covid-19 trouxe um novo olhar para a saúde nas empresas.
          </p>
          <p>
            O amplo conceito de saúde baseado em bem-estar físico, mental e social, em vez de apenas ausência de doença, nunca fez tanto sentido para as pessoas quanto agora.
          </p>
          <p>
            Na prática, essa consciência se traduz em um colaborador mais conectado com sua saúde e qualidade de vida e na necessidade de uma gestão de benefícios corporativos compatíveis com as novas demandas.
          </p>
          <p>
            O cenário também torna o ciclo virtuoso do <strong>ESG</strong> um novo direcionador estratégico do RH, já que não é mais possível falar sobre gestão da saúde sem preocupar-se com o <strong>social</strong>, o <strong>meio ambiente</strong> e sem adotar boas práticas de <strong>governança</strong>.
          </p>
          <p>
            Neste mercado em transformação surgem novos riscos de pessoas e também novos desafios na gestão de custos.
          </p>
          <p>
            <strong>Como vencê-los para aprimorar a gestão de saúde corporativa?</strong>
          </p>
          <div className="cols flex flex-sp flex-wr">
            <div className="col">
              <h2>
                <i></i>
                <span>
                  Quando vai ser?
                </span>
              </h2>
              <p>
                Dia 20 de Setembro de 2022, terça-feira, com início às <strong>9h</strong> para o público <strong>presencial</strong> e <strong>9h30</strong> para o público <strong>online</strong>.
              </p>
            </div>
            <div className="col">
              <h2>
                <i></i>
                <span>
                  Quem pode participar?
                </span>
              </h2>
              <p>
                Todos os profissionais de RH das empresas parceiras da <strong>Mercer Marsh Benefícios</strong>.
              </p>
              <p>
                A participação presencial é <strong>restrita a no máximo 2 representantes</strong> das empresas convidadas.
              </p>
            </div>
            <div className="col">
              <h2>
                <i></i>
                <span>
                  Onde vai ser?
                </span>
              </h2>
              <p>
                <strong>
                  Teatro B32 - O mais novo cartão postal da cidade de São Paulo
                </strong>
              </p>
              <p>
                <strong>Endereço:</strong> <br />
                <span>
                  Av. Brigadeiro Faria Lima, 3732 <br />
                  Itaim Bibi, São Paulo - SP
                </span>
              </p>
              <img src={fotosLocal} alt="Fotos local" />
            </div>
          </div>
        </div>
      </section>
      <section className="cadastro-presencial">
        <i className="linha-rosa"></i>
        <div className="container">
          <div className="cols flex flex-wr flex-sp">
            <div className="esq">
              <h2>
                As vagas presenciais são limitadas. <br />
                <span>Faça sua inscrição agora mesmo!</span>
              </h2>
              <p>
                Para você convidado da cidade de São Paulo e região metropolitana, será uma ótima oportunidade de nos reencontrarmos, fazermos networking e desfrutar da estrutura sustentável e super tecnológica do mais novo cartão postal da capital paulista!
              </p>
              <div className="box-login">
                <div className="box">
                  <CadastroPresencialComponent />
                </div>
              </div>
            </div>
            <div className="dir">
              <h2>
                <span>Não está em São Paulo?</span> <br />
                Participe online e ao vivo!
              </h2>
              <p>
                Para você convidado que é de fora de São Paulo ou que não conseguiu garantir sua vaga presencial, participe da transmissão do evento ao vivo para todo o Brasil, com possibilidade de interagir online com outros participantes e enviar suas perguntas para serem respondidas direto do palco!
              </p>
              <div className="box-login">
                <div className="box">
                  <CadastroComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sobre-mercer">
        <div className="container">
          <h2>
            <i></i>
            <span>
              Sobre a <br />
              Mercer Marsh Benefícios
            </span>
          </h2>
          <p>
            A <strong>Mercer Marsh Benefícios (MMB)</strong> é líder mundial em consultoria de riscos de pessoas e empresa de tecnologia de benefícios disruptivos número um, para formar um negócio único de benefícios para funcionários.
          </p>
          <p>
            Juntos, formamos algumas das experiências de benefícios para funcionários mais queridas do mundo para pequenas empresas, empresas em crescimento e empresas globais.
          </p>
          <p>
            Estamos presentes em 73 países, prestando serviços a clientes em mais de 150 países.
          </p>
          <p>
            Levamos a experiência local para mais lugares e trabalhamos em estreita colaboração com nossos clientes e nossos colegas da Mercer e da Marsh no mundo todo.
          </p>
          <img src={logoFoot} alt="Logo Mercer" />
        </div>
      </section>
      <section className="gestao-foot">
        <div className="container">
          <div className="box-foot">
            <h4>Patrocínio:</h4>
            <img src={logosFoot} alt="Logos" />
          </div>
        </div>
      </section>
      <div className={"modal-login flex flex-cv flex-ch ativo-" + showModalLogin}>
        <div className="box-login">
          {
            showBox === 'login' &&
            <div className="box">
              <LoginComponent />
              <Button onClick={() => { setShowBox('esqueci-minha-senha') }}>Esqueci minha senha</Button>
            </div>
          }
          {
            showBox === 'esqueci-minha-senha' &&
            <div className="box">
              <EsqueciMinhaSenhaComponent />
              <Button onClick={() => { setShowBox('login') }}>Efetuar login</Button>
            </div>
          }
          <Button className="fechar" onClick={() => { setShowModalLogin(false) }}>
            <Close />
          </Button>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
