
import EnvironmentModel from "./environment.model"

let env: EnvironmentModel = {
    env: "local",
    serverUrl: "http://localhost:8888/mercermarsh/backend",
    frontUrl: "",
    socketUrl: 'http://localhost:3001/'
};

const getEnv = (urlAtual: string) => {
    if ((urlAtual).indexOf('a7apps') >= 0) { // hml
        env = {
            env: "hml",
            serverUrl: "https://mercermarsh.a7apps.com/backend",
            frontUrl: "",
            socketUrl: 'https://bayerapiprd-env.eba-bp6d8zw3.us-east-2.elasticbeanstalk.com/'
        };
    } else if ((urlAtual).indexOf('mercermarsh.live') >= 0) { // prod
        env = {
            env: "prod",
            serverUrl: "https://mercermarsh.live/backend",
            frontUrl: "",
            socketUrl: 'https://bayerapiprd-env.eba-bp6d8zw3.us-east-2.elasticbeanstalk.com/'
        };
    } else if ((urlAtual).indexOf('localhost') >= 0) { // local
        env = {
            env: "local",
            serverUrl: "http://localhost:8888/mercermarsh/backend",
            frontUrl: "",
            socketUrl: 'http://localhost:3001/'
        };
    }
    
    return env;
}

export const environment: EnvironmentModel = getEnv(window.location.href);
