import axios from "axios";
import PerguntaModel from "../components/envie-sua-pergunta/pergunta.model";
import { environment } from "../environments/environment";
import UsuarioModel from "../pages/login/usuario.model";
import PesquisaSatisfacaoModel from "../pages/pesquisa-satisfacao/pesquisa-satisfacao.model";

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function PaginaTransmissaoLiveService(dto: UsuarioModel) {
    let url = '/paginas/transmissao-live';
    return await api.post(url, dto, {});
}

export async function PaginaAreaUsuarioService(dto: UsuarioModel) {
    let url = '/paginas/area-do-usuario';
    return await api.post(url, dto, {});
}

export async function PaginaLoginService(dto: UsuarioModel) {
    let url = '/paginas/login';
    return await api.post(url, dto, {});
}

export async function PaginaAgendaService(dto: UsuarioModel) {
    let url = '/apis/listar-agenda';
    return await api.post(url, dto, {});
}

export async function PaginaPalestrantesService(dto: UsuarioModel) {
    let url = '/apis/listar-palestrantes';
    return await api.post(url, dto, {});
}

export async function InserirPerguntaService(dto: PerguntaModel) {
    let url = '/apis/inserir-pergunta';
    return await api.post(url, dto, {});
}

export async function InserirPesquisaService(dto: PesquisaSatisfacaoModel) {
    let url = '/apis/inserir-pesquisa-satisfacao';
    return await api.post(url, dto, {});
}

export async function GetUserCartaoVirtualService(dto: UsuarioModel) {
    let url = '/apis/cartao-virtual';
    return await api.post(url, dto, {});
}