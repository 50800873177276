import axios from "axios";
import ReacoesModel from "../components/reacoes/reacoes.model";
import { environment } from "../environments/environment";

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function ListarReacoesService(dto: ReacoesModel) {
    let url = '/apis/listar-reacoes';
    return await api.post(url, dto, { cancelToken: dto.cancel });
}

export async function InserirReacaoService(dto: ReacoesModel) {
    let url = '/apis/inserir-reacao';
    return await api.post(url, dto, {});
}