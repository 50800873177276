import Routes from './routes';
import './App.scss';
import './fonts/bmw-motorrad-fonts/stylesheet.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
