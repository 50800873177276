
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HeaderComponent from './components/header/header.component';
import NotFoundPage from './pages/not-found/not-found.page';
import TransmissaoLivePage from './pages/transmissao-live/transmissao-live.page';
import SnackComponent from './components/snack/snack.component';
import LoginPage from './pages/login/login.page';
import ProtectedRoute, { ProtectedRouteProps } from './protected-route';
import LoadingComponent from './components/loading/loading.component';
import FooterComponent from './components/footer/footer.component';
import AreaUsuarioPage from './pages/area-usuario/area-usuario.page';
import { environment } from './environments/environment';
import DownloadsPage from './pages/downloads/downloads.page';
import { MinhaContaPage } from './pages/minha-conta/minha-conta.page';
import { CartaoVirtualComponent } from './components/cartao-virtual/cartao-virtual.component';
import { AgendaPage } from './pages/agenda/agenda.page';
import { PalestrantesPage } from './pages/palestrantes/palestrantes.page';
import { PesquisaSatisfacaoPage } from './pages/pesquisa-satisfacao/pesquisa-satisfacao.page';


function Routes() {

    return (
        <BrowserRouter>
            <HeaderComponent />
            <Switch>
                <Route exact path={environment.frontUrl + "/"} component={LoginPage} />
                <ProtectedRoute
                    exact={true}
                    path={environment.frontUrl + '/transmissao-ao-vivo'}
                    component={TransmissaoLivePage}
                />
                <ProtectedRoute
                    exact={true}
                    path={environment.frontUrl + '/area-usuario'}
                    component={AreaUsuarioPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={environment.frontUrl + '/area-de-download'}
                    component={DownloadsPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={environment.frontUrl + '/minha-conta'}
                    component={MinhaContaPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={environment.frontUrl + '/agenda'}
                    component={AgendaPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={environment.frontUrl + '/palestrantes'}
                    component={PalestrantesPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={environment.frontUrl + '/pesquisa-satisfacao'}
                    component={PesquisaSatisfacaoPage}
                />
                <Route exact path={environment.frontUrl + "/login"} component={LoginPage} />

                <Route component={NotFoundPage} />
            </Switch>
            <FooterComponent />
            <CartaoVirtualComponent />
            <SnackComponent />
            <LoadingComponent />
        </BrowserRouter>
    )
}

export default Routes;