import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import './downloads.page.scss';
import UsuarioModel from '../login/usuario.model';
import { getAuthUser } from '../../services/auth';
import { SnackService } from '../../services/snack.service';
import DownloadsModel from './downloads.model';
import { LoadingService } from '../../services/loading.service';
import CategoriasModel from './categorias.model';
import { ArrowRight } from '@material-ui/icons';
import { ListarCategoriasService, ListarDownloadsService } from '../../services/api-downloads.service';

function DownloadsPage() {

  var dto: UsuarioModel = {};
  var dtoCategoria: CategoriasModel = {};
  var usuario: UsuarioModel = getAuthUser();
  const [listaCategorias, setListaCategorias] = React.useState<Array<CategoriasModel>>([]);
  const [listaDownloads, setListaDownloads] = React.useState<Array<DownloadsModel>>([]);
  const [tituloCategoriaAtual, setTituloCategoriaAtual] = React.useState<string | undefined>('');

  useEffect(() => {
    getListCategorias();
  }, []);

  const getListCategorias = () => {

    dto = {
      ID: usuario.ID
    };

    LoadingService.sendLoadingAtivo(true);

    ListarCategoriasService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListaCategorias(res.data);
        getListaDownloads(res.data[0]);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const getListaDownloads = (categoria: CategoriasModel) => {

    dtoCategoria = {
      id: categoria.id
    };

    setTituloCategoriaAtual(categoria.categoria);

    LoadingService.sendLoadingAtivo(true);

    ListarDownloadsService(dtoCategoria).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListaDownloads(res.data);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  return (
    <section className="page downloads flex flex-cv">
      <div className="container-fluid">
        <div className="flex flex-ch flex-wr">
          <div className="esq">
            <h1>Downloads</h1>
            <div className="itens flex flex-ch flex-cv flex-wr">
              {
                listaCategorias ? listaCategorias?.map((item, indexItem) => (
                  <Button key={indexItem} onClick={() => getListaDownloads(item)}>
                    <span>{item.categoria}</span>
                    <ArrowRight />
                  </Button>
                )) : <span>Nenhum item encontrado.</span>
              }
            </div>
          </div>
          <div className="dir">
            <h2>{tituloCategoriaAtual}</h2>
            <div className="itens flex flex-cv flex-wr">
              {
                listaDownloads.length > 0 ? listaDownloads?.map((item, indexItem) => (
                  <div className="item flex flex-cv flex-sp flex-wr" key={indexItem}>
                    <h4>{item.titulo}</h4>
                    <Button href={item.download} target="_blank">
                      <span>Download</span>
                    </Button>
                  </div>
                )) :
                  <div className="item">
                    <span>Nenhum item encontrado.</span>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DownloadsPage;
