import React from 'react';
import { Button, TextField } from '@mui/material';
import { useInput } from '../../../hooks/useInput.hook';
import { CadastrarService } from '../../../services/api-usuario.service';
import { LoadingService } from '../../../services/loading.service';
import { SnackService } from '../../../services/snack.service';
import UsuarioModel from '../usuario.model';
import './cadastro-presencial.component.scss';

function CadastroPresencialComponent() {

  const { value: emailInputText, bind: bindEmailInputText, setValue: setEmailInputText } = useInput('');
  const { value: nomeCompletoInputText, bind: bindNomeCompletoInputText, setValue: setNomeCompletoInputText } = useInput('');
  const { value: empresaInputText, bind: bindEmpresaInputText, setValue: setEmpresaInputText } = useInput('');
  const { value: cidadeInputText, bind: bindCidadeInputText, setValue: setCidadeInputText } = useInput('');
  const { value: estadoInputText, bind: bindEstadoInputText, setValue: setEstadoInputText } = useInput('');

  var dto: UsuarioModel = {};

  const Cadastrar = (e: any) => {
    e.stopPropagation();

    if(!emailInputText){
      SnackService.sendMSG('O campo e-mail é obrigatório.');
      return;
    }

    if(!nomeCompletoInputText){
      SnackService.sendMSG('O campo nome é obrigatório.');
      return;
    }

    if(!cidadeInputText){
      SnackService.sendMSG('O campo cidade é obrigatório.');
      return;
    }

    if(!estadoInputText){
      SnackService.sendMSG('O campo estado é obrigatório.');
      return;
    }

    dto = {
      user_email: emailInputText,
      first_name: nomeCompletoInputText,
      empresa: empresaInputText,
      cidade: cidadeInputText,
      estado: estadoInputText,
      tipo_perfil: "Presencial"
    };

    LoadingService.sendLoadingAtivo(true);

    CadastrarService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res?.data?.email_exists) {
          SnackService.sendMSG(res?.data?.email_exists);
        } else if (res?.data?.limit_users_online) {
          SnackService.sendMSG(res?.data?.limit_users_online);
        } else if (res?.data?.limit_users_presencial) {
          SnackService.sendMSG(res?.data?.limit_users_presencial);
        } else if (res?.data?.random_password) {
          SnackService.sendMSG('Usuário cadastrado, enviamos um e-mail com a sua senha.');
          LimparForm();
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const LimparForm = () => {
    setEmailInputText('');
    setNomeCompletoInputText('');
    setEmpresaInputText('');
    setCidadeInputText('');
    setEstadoInputText('');
  }

  return (
    <div className="cadastro-component">
      <h3>
        Cadastre-se no evento presencial
      </h3>
      <TextField {...bindEmailInputText} label="E-mail" />
      <TextField {...bindNomeCompletoInputText} label="Nome completo" />
      <TextField {...bindEmpresaInputText} label="Empresa" />
      <TextField {...bindCidadeInputText} label="Cidade" />
      <TextField {...bindEstadoInputText} label="Estado" />
      <Button onClick={Cadastrar} className="bt">Enviar</Button>
    </div>
  );
}

export default CadastroPresencialComponent;
