import React, { useEffect } from 'react';
import { PaginaAgendaService } from '../../services/api-paginas.service';
import { getAuthUser } from '../../services/auth';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import UsuarioModel from '../login/usuario.model';
import AgendaModel from './agenda.model';
import './agenda.page.scss';

export function AgendaPage() {

  var usuario: UsuarioModel = getAuthUser();
  var dto: UsuarioModel = {};

  const [listaAgenda, setListaAgenda] = React.useState<Array<AgendaModel>>([]);

  useEffect(() => {
    PaginaAgenda();
  }, []);

  const PaginaAgenda = () => {

    dto = {
      user_email: usuario.user_email
    };

    LoadingService.sendLoadingAtivo(true);

    PaginaAgendaService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setListaAgenda(res.data.agenda);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  return (
    <section className="page agenda flex flex-cv">
      <div className="container-fluid">
        <div className="flex flex-ri flex-cv flex-wr">
          <div className="esq">
            <h1>Agenda</h1>
          </div>
          <div className="dir">
            <div className="itens">
              {
                listaAgenda ? listaAgenda?.map((item, indexItem) => (
                  <div key={indexItem} className="item flex flex-cv">
                    <div className="esq-item">
                      <div className="mask">
                        <i></i>
                        <h4>{item.data_hora}</h4>
                      </div>
                    </div>
                    <div className="dir-item">
                      <h2>{item.titulo}</h2>
                    </div>
                  </div>
                )) : <span>Nenhum item encontrado.</span>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
